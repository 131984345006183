<template>
	<div>
		<!-- 认证 -->
		<div v-if="loadingBig">
			<loadingBig></loadingBig>
		</div>
		<div v-if="state==0&&!loadingBig">
			<van-form @submit="onSubmit">
				<div class="authenticationForm">
					<van-field v-model="businessName" clearable name="businessName" label="商家名称" placeholder="请输入商家(店铺)名称" :rules="[{ required: true, message: '请输入商家(店铺)名称' }]" />
					<van-field readonly v-model="userInfo.memberName" name="businessLeadingPerson" label="商家负责人" placeholder="请输入商家负责人" :rules="[{ required: true, message: '请输入商家负责人' }]" />
					<van-field readonly v-model="userInfo.memberPhone" clearable name="tel" label="联系电话" placeholder="请输入商家负责人电话" :rules="[{ pattern, message: '请输入商家负责人电话' }]" />


					<van-field v-model="sms" name="smsCode" center clearable label="验证码" placeholder="请输入验证码" :rules="[{ required: true, message: '请输入验证码' }]">
						<template #button>
							<div class="codeBtn" @click="sendCode">{{btnText}}</div>
						</template>
					</van-field>

					<van-field readonly clickable name="region" :value="region" label="所在区域" right-icon="arrow" placeholder="请选择您的省市县"
					 :rules="[{ required: true, message: '请选择您的省市县' }]" @click="showArea = true" />
					<!-- 选择省市区 -->
					<van-popup v-model="showArea" position="bottom">
						<van-area :area-list="areaList" @confirm="onConfirm" @cancel="showArea = false" />
					</van-popup>

					<van-field clearable v-model="address" name="address" label="详细地址" placeholder="请输入您的详细地址" :rules="[{ required: true, message: '请输入您的详细地址' }]" />
					
					<van-field clearable v-model="unifiedCreditCode" name="unifiedCreditCode" label="社会统一信用代码" placeholder="请输入您的社会统一信用代码" :rules="[{ required: true, message: '请输入您的社会统一信用代码' }]" />


					<!-- <div style="width: 82vw;padding: 10px 4vw;">
						<div class="text-14 text999">上传门头照或其他可以证明营业的凭证（{{imgList.length}}/9）</div>
						<van-uploader v-model="fileList" :after-read="afterRead" :before-delete="deleteImg" style="margin-top: 12px;" :max-count="9" />
					</div> -->

				</div>
				<div style="margin: 16px;">
					<van-button :disabled="loadingBtn" round block type="info" :color="colorBtn" native-type="submit">提交</van-button>
				</div>
			</van-form>

			<van-popup round v-model="showTips">
				<div style="width: 80vw;" class="center">
					<img style="width: 32vw;height: 34.7vw;padding-top: 20px;" src="../../assets/images/success.png" />
					<div class="text-18 blod" style="padding: 19px 0 9px 0;">提交成功！</div>
					<div class="text-14 gray">您的申请正在审核，请稍等</div>
					<div style="padding: 21px 0 26px 0;" @click="success">
						<van-button style="width: 64vw;" round block type="info" :color="colorBtn" native-type="submit">我知道了</van-button>
					</div>
				</div>

			</van-popup>
		</div>

		<!-- 认证失败 -->
		<div v-if="state==1&&!loadingBig">
			<div style="width: 90vw;height: 81.3vw;position: fixed;top: 0;bottom: 0;left: 0;right: 0;margin: auto;">
				<div style="width: 90vw;margin: auto;" class="center">
					<img style="width: 32vw;height: 34.7vw;padding-top: 20px;" src="../../assets/images/fail.png" />
					<div class="text-16" style="padding: 19px 0 9px 0;">审核失败，您的审核未通过！</div>
					<div class="text-14 gray">{{authenticationInfo.cnote}}</div>
					<div style="padding: 21px 0 26px 0;" @click="again">
						<van-button style="width: 90vw;" round block type="info" :color="colorBtn" native-type="submit">重新提交</van-button>
					</div>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import {
		Toast
	} from 'vant';
	import {
		areaList
	} from '@vant/area-data';
	import loadingBig from "@/components/loading-big.vue"
	import { Dialog } from 'vant';
	export default {
		extends: BaseView,
		components:{
			loadingBig
		},
		data() {
			return {
				title: "商家认证",
				loadingBig:true,//页面加载
				userInfo:{},
				state: 0,
				backcolor: "#F5F5F5",
				showArea: false,
				sms: "",
				// province: "",
				// city: "",
				// area: "",
				// username: "",
				// director: "",
				// phone: "",
				
				// areaInfo: "",
				// address: "",
				areaList: areaList,
				showTips: false,
				fileList: [],
				imgList: [],
				btnText: "发送验证码",
				seconds: 60,
				disable:false,
				regionCode:0,
				imgIdList:[],
				authenticationInfo:{},
				businessName:"",
				region:"",
				address:"",
				unifiedCreditCode:""
			}
		},
		created() {
			this.getAuthentication()
			this.getUserInfo()
		},
		methods: {
			//查询用户信息
			getUserInfo() {
				this.$axios.get("member/base/memberInfo").then(res => {
					console.log("用户信息", res)
					this.userInfo = res.data
					
					if((!this.userInfo.memberName)||(!this.userInfo.memberPhone)){
						Dialog.confirm({
						  title: '完善信息',
						  message:
						    '请前往设置完善信息！',
						})
						  .then(() => {
						    // on confirm
							this.$router.replace("/means")
						  })
						  .catch(() => {
						    // on cancel
							this.$router.go(-1)
						  });
						// Toast("请前往设置完善信息")
						// setTimeout(()=>{
						// 	this.$router.go(-1)
						// },2000)
					}
					// this.authenticationInfo.businessLeadingPerson=res.data.memberName
					// this.authenticationInfo.tel=res.data.memberPhone
				})
			},
			//查询详情
			getAuthentication(){
				this.$axios.get("business/base/infoBusiness").then(res=>{
					console.log("认证详情",res)
					setTimeout(()=>{
						this.loadingBig=false
					},500)
					if(res.code==1){
						
						this.authenticationInfo=res.data
						
						if(this.authenticationInfo){
							//0 未认证 2 审核中 6 已认证  7 审核失败
							if(this.authenticationInfo.businessStatus==6){
								//认证成功，查询详情
							}else if(this.authenticationInfo.businessStatus==7){
								//审核未通过
								this.state = 1
								document.body.style.backgroundColor = "#FFFFFF"
							}else if(this.authenticationInfo.businessStatus==2){
								//审核中
								this.showTips=true
							}
							
							this.businessName=this.authenticationInfo.businessName
							this.region=this.authenticationInfo.region
							this.address=this.authenticationInfo.address
							this.regionCode=this.authenticationInfo.regionCode
							this.unifiedCreditCode=this.authenticationInfo.unifiedCreditCode
							
							// this.imgList=this.authenticationInfo.licenseUrls.split(",")
							// this.imgIdList=this.authenticationInfo.licenseImgIds.split(",")
							// for(var i=0;i<this.imgList.length;i++){
							// 	// this.imgList[i]=this.imageUrl+this.imgList[i]
							// 	this.fileList.push({
							// 		url:this.imgList[i]
							// 	})
							// }
						}
						console.log("***",this.fileList)
						// this.areaInfo=this.authenticationInfo.region
						// this.regionCode=this.authenticationInfo.regionCode
					}
					console.log("**********",this.authenticationInfo)
				})
			},
			onConfirm(e) {
				console.log("选择地址",e)
				this.region = e[0].name + e[1].name + e[2].name
				this.regionCode=e[0].code + e[1].code + e[2].code
				// this.province = e[0].name
				// this.city = e[1].name
				// this.area = e[2].name
				this.showArea = false

			},
			success() {
				// this.state = 1
				this.showTips = false
				this.$router.go(-1)
				// document.body.style.backgroundColor = "#FFFFFF"
			},
			again() {
				this.state = 0
				document.body.style.backgroundColor = "#F5F5F5"
				
			},
			//发送验证码
			sendCode() {
				if(this.disable){
					console.log("禁止点击")
					return;
				}
				if (!this.pattern.test(this.userInfo.memberPhone)) {
					Toast("请输入正确的手机号")
					return;
				}
				this.seconds=60
				this.btnText = this.seconds + "s"
				let param = {
					phone: this.userInfo.memberPhone,
					type: 0
				}
				console.log("验证码", param)
				
				this.$axios.post("smsCode/base/sendMessageCode",param).then(res=>{
					console.log("发送验证码",res)
					if(res.code==1){
						Toast("发送成功！")
						if (this.seconds > 0) {
							let timer = setInterval(() => {
								if (this.seconds > 0) {
									this.seconds--
									this.btnText = this.seconds + "s"
									this.disable=true
								} else {
									clearInterval(timer)
									this.btnText = "重新发送"
									this.disable=false
								}
						
							}, 1000)
						}

					}else{
						Toast("发送失败！")
						this.btnText="重新发送"
						this.disable=false
					}
				})
			},
			//提交商家认证
			onSubmit(value) {
				// if(this.imgIdList.length==0){
				// 	Toast("请上传凭证")
				// 	return;
				// }
				this.loadingBtn=true
				value.regionCode=this.regionCode
				// var img=this.imgList.toString()
				var imgId=this.imgIdList.toString()
				value.licenseJson="1111"
				console.log("提交", value)
				this.$axios.post("business/base/authenticationBusiness",value).then(res=>{
					console.log("提交商家认证",res)
					if(res.code==1){
						this.showTips = true
					}else{
						Toast(res.msg)
					}
					this.loadingBtn=false
				})
				
			},
			//图片上传
			afterRead(file) {
				// 此时可以自行将文件上传至服务器
				console.log(file);
				file.status = 'uploading'
				file.message = "上传中"
				var datas = new FormData()
				datas.append('file', file.file)
				this.$axios.post("img/base/imgUpLoad", datas).then(res => {
					console.log("文件上传", res)
					if (res.code == 1) {
						file.status = 'done'
						this.imgList.push(res.data.url)
						this.imgIdList.push(res.data.id)
						console.log("图片list",this.imgIdList)
					} else {
						file.status = 'failed'
						file.message = "上传失败"
					}
				}).catch(err => {
					// console.log("错误",err)
					file.status = 'failed'
					file.message = "上传失败"
				})
			},
			//删除图片
			deleteImg(file, detail) {
				console.log("删除", file)
				console.log("删除2", detail)
				this.imgList.splice(detail.index, 1)
				this.fileList.splice(detail.index, 1)
				this.imgIdList.splice(detail.index, 1)
			}
		}
	}
</script>

<style>
	.authenticationForm {
		width: 90vw;
		background: #FFFFFF;
		border-radius: 10px;
		margin: auto;
		margin-top: 15px;
	}

	.authenticationForm .van-cell-group {
		border-radius: 10px;
	}

	.authenticationForm .van-cell {
		border-radius: 10px;
	}

	.authenticationForm .van-field__label {
		color: #999999;
	}

	.codeBtn {
		width: 65px;
		height: 24px;
		background: #FFFFFF;
		border: 1px solid #0080FD;
		border-radius: 5px;
		text-align: center;
		line-height: 24px;
		font-size: 11px;
		color: #0080FD;
	}
</style>
