<template>
	<div>
		<div class="margin-top-10">
			<div v-if="showSkeleton">
				<div class="width82 backgroundWhite radius5 margin-bottom-10" v-for="i in 10" :key="i">
					<van-skeleton :row="3" />
				</div>

			</div>
			<van-empty v-if="!showSkeleton&&peopleList.length<=0" class="custom-image" :image="emptyImg" description="您还没有送样员" />
			<div v-if="!showSkeleton&&peopleList.length>0">
				<van-pull-refresh v-model="refreshing" @refresh="onRefresh">
					<van-list v-model="loading" :finished="finished" finished-text="没有更多了" @load="onLoad" :immediate-check="false">
						<div class="width82 backgroundWhite radius5 margin-bottom-10" v-for="(item,index) in peopleList" :key="index" @click="clickSample(item)">
							<div class="text-14">
								人员姓名:{{item.sampleName}}
							</div>
							<div class="text-14 padding-top-5">
								微信昵称:{{item.vxNickName}}
							</div>
							<div class="text-14 betweenCenter">
								<div>手机号码:{{item.tel}}</div>
								<div class="peopleBtn peopleBtnBg" @click.stop="deletePeople(item)">
									删除
								</div>
								<!-- <div class="peopleBtn" :class="[item.state==2?'peopleBtnBg':'peopleBtnBgGray']" @click.stop="updateState(item)">
									{{item.state==2?'解冻':'冻结'}}
								</div> -->
							</div>
						</div>
					</van-list>
				</van-pull-refresh>
			</div>

		</div>
		
		<!-- 二维码 -->
		<van-popup v-model="showQRcode" round>
			<div class="text-14" style="text-align: center;margin-top: 10px;">
				添加送检员
			</div>
			<div class="QRCode">
				<vue-qr class="code" ref="Qrcode" :text="dataObj.url" :callback="qrcode" qid="testQrId"></vue-qr>
			</div>
		</van-popup>
		

		<div style="height: 60px;"></div>
		<div class="borderBtn">
			<van-button @click="add" style="width: 90vw;" :color="colorBtn" type="primary" round>添加送样员</van-button>
		</div>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue"
	import VueQr from 'vue-qr'
	import {bus} from "@/bus.js"
	import {Toast} from 'vant';
	import { Dialog } from 'vant';
	export default {
		extends: BaseView,
		components: {
			VueQr
		},
		data() {
			return {
				title: "送样员管理",
				state:1,//从设置进入0
				showQRcode:false,
				showSkeleton: true,
				disableBtn: false,
				refreshing: false,
				loading: false,
				finished: false,
				param: {
					page: 1,
					limit:10
				},
				total: 0,
				peopleList: [],
				dataObj:{}
			}
		},
		created() {
			this.getSample()
			this.getUserInfo()
			this.state=this.$route.query.state
		},
		methods: {
			//查询用户信息
			getUserInfo(){
				this.$axios.get("member/base/memberInfo").then(res=>{
					console.log("用户信息",res)
					// this.dataObj.url="https://192.168.1.45:8081/#/addSample?ueseId="+res.data.id
					// this.dataObj.url="https://www.ebaoku.com/#/addSample?ueseId="+res.data.id
					this.dataObj.url="https://testzhijiandbnew.cugi.cn/#/addSample?ueseId="+res.data.id
				})
			},
			//  生成二维码
			qrcode(dataUrl, id) {
				console.log("二维码a",dataUrl)
				console.log("二维码b",id)
			},
			//查询送样员
			getSample() {
				this.$axios.get("member/sample/sampleListPage", {
					params: this.param
				}).then(res => {
					console.log("送样员列表", res)
					this.showSkeleton = false
					this.refreshing = false
					this.loading = false
					if (this.param.page == 1) {
						this.peopleList = res.data
					} else {
						this.peopleList = this.peopleList.concat(res.data)
					}
					this.total = res.count
					if (this.total == this.peopleList.length) {
						this.finished = true
					}
				})
			},
			//删除送样员
			deletePeople(item){
				Dialog.confirm({
				  title: "提示",
				  message: "请确定是否删除送样员",
				})
				  .then(() => {
				    // on confirm
					var param={
						sampleId:item.id
					}
					console.log("修改",param)
					this.$axios.post("member/sample/deleteSampleState",param).then(res=>{
						console.log("修改",res)
						this.onRefresh()
					})
				  })
				  .catch(() => {
				    // on cancel
				  });
			},
			//修改送样员状态
			updateState(item){
				Dialog.confirm({
				  title: item.state==2?"解冻":"冻结",
				  message: "确定"+(item.state==2?"解冻":"冻结")+"?",
				})
				  .then(() => {
				    // on confirm
					var param={
						sampleId:item.id
					}
					if(item.state==2){
						param.state=1
					}else{
						param.state=2
					}
					console.log("修改",param)
					this.$axios.post("member/sample/updateSampleState",param).then(res=>{
						console.log("修改",res)
						this.onRefresh()
					})
				  })
				  .catch(() => {
				    // on cancel
				  });
				
			},
			//选择送样员
			clickSample(item){
				if(this.state!=0){
					if(item.state==2){
						Toast("冻结中···")
						return;
					}
					bus.$emit("sample",item)
					this.$router.back()
				}
				
			},
			//添加送样员，展示二维码
			add() {
				this.showQRcode=true
			},
			onRefresh() {
				this.refreshing = true
				this.finished = false
				// this.showSkeleton = true
				this.param.page = 1
				// this.peopleList = []
				this.getSample()
			},
			onLoad() {
				if (this.total <= this.peopleList.length) {
					this.finished = true
					return;
				}
				this.param.page++
				this.getSample()
			}
		}
	}
</script>

<style>
	.billBtn {
		width: 70px;
		height: 28px;
		text-align: center;
		line-height: 28px;
		border-radius: 28px;
		color: #FFFFFF;
		border: #FFFFFF 1px solid;
		font-size: 12px;
	}

	.peopleBtn {
		width: 60px;
		height: 28px;
		text-align: center;
		line-height: 28px;
		color: #FFFFFF;
		border-radius: 28px;
		font-size: 12px;
	}

	.peopleBtnBg {
		background: linear-gradient(to right, #03B0F9, #0080FD);
	}

	.peopleBtnBgGray {
		background: #E2E3E5;
	}
	/* 二维码 */
	.QRCode {
		width: 55vw;
		height: 55vw;
		background: #D7D9DF;
		margin: auto;
		/* margin-top: 10px; */
	}
	.code{
		width: 55vw;
		height: 55vw;
	}
</style>
