<template>
	<div>
		<div v-if="loadingBig">
			<loadingBig></loadingBig>
		</div>
		
		<!-- 内容 -->
		<div class="width90 margin-top-10" v-else>
			
			<van-image fit="cover" class="contenImg" :src="certificateInfo.certificateImgUrl" @click="imagePreview([certificateInfo.certificateImgUrl])" ></van-image>
			<div>
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">鉴定结果</div>
					<div>{{certificateInfo.checkResult}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">证书编号</div>
					<div>{{certificateInfo.certificateNumber}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">总质量</div>
					<div>{{certificateInfo.totalMass}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">颜色</div>
					<div>{{certificateInfo.color}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">样式</div>
					<div>{{certificateInfo.style}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">形状</div>
					<div>{{certificateInfo.shape}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">放大检查</div>
					<div>{{certificateInfo.enlargeCheck}}</div>
				</div>
					
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">紫外可见光谱</div>
					<div>{{certificateInfo.ultravioletVisibleSpectrum}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">红外检测描述</div>
					<div>{{certificateInfo.infraredDescription}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
					<div class="gray">折射率</div>
					<div>{{certificateInfo.refractive}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">密度</div>
					<div>{{certificateInfo.density}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">是否测配石数</div>
					<div>{{certificateInfo.checkStoneNumber==1?"是":"否"}}</div>
				</div>	
				
				<!-- 翡翠 -->
				<!-- <div  v-if="state==0">
					
				</div> -->
				
				<!-- 黄金 -->
				<!-- <div v-if="state==1">
					<div class="between borderBottom paddingtb10 text-14">
						<div class="gray">印记</div>
						<div>显示印记</div>
					</div>
				</div> -->
				
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">其他</div>
					<div>{{certificateInfo.other}}</div>
				</div>
				
				<div class="between borderBottom paddingtb10 text-14">
					<div class="gray">备注</div>
					<div>{{certificateInfo.cnote}}</div>
				</div>
				
			</div>
			<!-- <div v-if="certificateInfo.sampleImg">
				<van-image fit="cover" class="contenImg" :src="certificateInfo.sampleImg"></van-image>
			</div> -->
			<div v-if="certificateInfo.sampleImgUrls">
				<div v-for="(img,key) in certificateInfo.sampleImgUrls" :key="key" >
					<van-image fit="cover" class="contenImg" :src="img.cutUrl" @click="imagePreview([img.cutUrl])"></van-image>
				</div>
			</div>
			
			
			
		</div>
	</div>
</template>

<script>
	import BaseView from "@/views/BaseView.vue";
	import loadingBig from "@/components/loading-big.vue"
	import { ImagePreview } from 'vant';
	export default{
		extends:BaseView,
		components:{
			loadingBig
		},
		data(){
			return{
				loadingBig:true,//页面加载
				title:"详情",
				backcolor: "#FFFFFF",
				certificateCode:"",
				state:0,//0翡翠1黄金
				certificateId:0,
				certificateInfo:""
			}
		},
		created(){
			this.certificateId=this.$route.query.certificateId
			this.getCertificateDetail()
		},
		methods:{
			//查询详情
			getCertificateDetail(){
				this.$axios.get("/certificate/base/certificateDetails",{
					params:{certificateId:this.certificateId}
				}).then(res=>{
					
					var certificateInfo=res.data
					// var str=certificateInfo.shape.split(",")
					// certificateInfo.style=str[0]//样式
					// certificateInfo. fashion=str[1]//形状
					console.log("证书详情",certificateInfo)
					setTimeout(()=>{
						this.loadingBig=false
					},500)
					
					this.certificateInfo=res.data
					
				})
			},
			//扫码
			sacn(){
				console.log("扫码")
			},
			//搜索
			search(){
				console.log("搜索",this.certificateCode)
				
			},
			//预览图片
			imagePreview(img){
				console.log("预览图片",img)
				ImagePreview(img);
			}
		}
		
	}
</script>

<style>
	.searchInput{
		border-top-left-radius: 34px;
		border-bottom-left-radius: 34px;
	}
	.input .van-cell{
		line-height: 14px !important;
	}
	.search{
		background: #0080FD;
		color: #FFFFFF;
		width: 50px;
		height: 34px;
		border-top-right-radius: 34px;
		border-bottom-right-radius: 34px;
		text-align: center;
		line-height: 34px;
		font-size: 12px;
	}
	.contenImg{
		width: 90vw;
		/* height: 58.7vw; */
		/* border-radius: 10px; */
		margin-top: 10px;
	}
	.van-image__img{
		border-radius: 0 !important;
	}
</style>
