<!--基础页面：可设置背景色；默认判断缓存里面是否有access_token-->

<script>
	import Vue from 'vue';
	import {
		Toast
	} from 'vant';
	Vue.use(Toast);
	import wx from 'weixin-js-sdk'
	export default {
		name: "BaseView",

		data() {
			return {
				title: "质检测",
				init_wx: this.configVo.init_wx, //微信配置
				loadingBtn: false, //确定提交按钮是否可点击
				defaultColor: "#0080FD", //主题色
				colorBtn: 'linear-gradient(to right,#03B0F9,#0080FD)', //vant按钮颜色
				backcolor: "#F5F5F5", //背景色
				imageUrl: "https://erpbaoku.oss-cn-chengdu.aliyuncs.com//",
				qrCode: "https://fcxcx.kmmyxb.net.cn/mht/", //名片二维码
				emptyImg: require("@/assets/images/empty.png"),
				pattern: /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/,
				// before_backcolor:'',//进入此页面之前的背景色
			}
		},
		
		methods: {
			/** method post 或者get
			 *url 接口地址
			 * params 参数
			 * comfunc 访问成功执行的方法
			 * loading_tip 加载中的提示文本
			 */
			request(method, url, params, comfunc, loading_tip = "") {
				// Toast.clear()
				if (loading_tip) {
					// thia.$router.replace()
					this.$toast.loading({
						message: loading_tip,
						forbidClick: true,
						duration: 0,
					});
				}

				var access_token = localStorage.getItem("access_token")
				console.log("access_token", access_token)
				var that = this;
				this.$http({
					url: url,
					method: method,
					data: params,
					params: method == "GET" || method == "get" ? params : "",
					headers: {
						'Authorization': access_token
					} //设置header信息
				}).then((res) => {
					this.$toast.clear();
					if (res.headers.authorization) {
						localStorage.setItem('access_token', res.headers.authorization);
						// that.request(method, url, params, comfunc, loading_tip,false );
						// return;
					}
					var data = res.data;

					if (data.code == 401) {
						// https://fcxcx.kmmyxb.net.cn/mht/#/personal
						// localStorage.setItem('redirect_uri', window.location.href);
						// "https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1ad4d1d3f36426ed&redirect_uri="+window.location.href+"&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"
						// that.$toast(data.msg);
						that.$router.push("pwdlogin");
					} else if (data.code == 2001 || data.code == 2002) { //预支付编号有误，返回首页
						that.$toast(data.msg);
						that.$router.push("/");
					} else if (data.code != 0) {
						that.$toast(data.msg);
						comfunc(data.code);
					} else { //成功
						if (loading_tip) {
							// Toast.clear()
						}
						comfunc(data.result);
					}


					// console.log('that.shop_info',this.shop_info);
				}).catch(function(error) {
					// this.$toast.clear()
					// console.log(error);
					// console.log(error.response.data.errors);
					if (!error.response) {
						return;
					}
					if (error.response.status == 401) {
						// var code = that.getUrlParam("code")
						// if (code != null) {
						// 	that.login(code);
						// 	return;
						// };
						// that.$toast("登陆失效");
						// let redirect_uri = encodeURIComponent(window.location.href)
						// window.location.href =
						// 	"https://open.weixin.qq.com/connect/oauth2/authorize?appid=wx1ad4d1d3f36426ed&redirect_uri=" + redirect_uri +
						// 	"&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_redirect"

						// that.$router.push("pwdlogin");
					} else {
						if (error.response.data && error.response.data.errors) {
							that.$toast(url + "：" + JSON.stringify(error.response.data.errors));
						} else {
							that.$toast(url + "：" + error);
						}

					}
					// console.log(error.response.status);
					// console.log(error);
				});
			},
			// 获取url上的参数
			getUrlParam(name) {
				let reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)"); //构造一个含有目标参数的正则表达式对象
				let r = window.location.search.substr(1).match(reg); //匹配目标参数
				if (r != null) return decodeURIComponent(r[2]);
				return null; //返回参数值　
			},
			escape2Html(str) {
				var arrEntities = {
					'lt': '<',
					'gt': '>',
					'nbsp': ' ',
					'amp': '&',
					'quot': '"'
				};
				return str.replace(/&(lt|gt|nbsp|amp|quot);/ig, function(all, t) {
					return arrEntities[t];
				});
			},
			//时间
			/**
			 * @param intervalDays ：间隔天数
			 */
			getDateRange(intervalDays) {
				let oneDayTime = 24 * 60 * 60 * 1000;
				var dateNow = new Date();
				let list = [];
				let lastDay;
				lastDay = new Date(dateNow.getTime() - intervalDays * oneDayTime);
				list.push(this.formateDate(lastDay));
				list.push(this.formateDate(dateNow));

				return list;
			},
			//时间格式化
			formateDate(time) {
				let year = time.getFullYear()
				let month = time.getMonth() + 1
				let day = time.getDate()
				let h = time.getHours()
				let m = time.getMinutes()
				return year + '-' + month + '-' + day + " " + h + ":" + m
			},

			//获取经纬度
			getLocation() {
				var that = this
				wx.checkJsApi({
					jsApiList: [
						'getLocation'
					],
					success: function(res) {
						console.log("是否支持", res)
						// alert(JSON.stringify(res));
						// alert(JSON.stringify(res.checkResult.getLocation));
						if (res.checkResult.getLocation == false) {
							alert('你的微信版本太低，不支持微信JS接口，请升级到最新的微信版本！');
							return;
						}
					}
				});
				wx.ready(function() {
					wx.getLocation({
						success: function(res) {
							console.log("定位", res);
							localStorage.setItem("longAlat", JSON.stringify(res))
							that.getLatelySite()

							// this.longitude=res.longitude//经度
							// this.latitude=res.latitude//纬度
						},
						cancel: function(res) {
							console.log('用户拒绝授权获取地理位置', res);
						}
					});
				});
				wx.error(function(res) {
					console.log("错误", res)
				})
			},
			//查询最近站点
			getLatelySite() {
				var longAlat = JSON.parse(localStorage.getItem("longAlat"))
				this.$axios.get("/site/base/latelySite", {
					params: {
						memberLatitude: longAlat.latitude,
						memberLongitude: longAlat.longitude
					}
				}).then(res => {
					console.log("最近站点", res)
					this.siteInfo = res.data
					this.siteName = this.siteInfo.siteName
					localStorage.setItem("siteInfo", JSON.stringify(this.siteInfo))
					window.location.reload();

				})
			},

		},
		beforeDestroy: function() {
			// document.body.style.backgroundColor = "#FFFFFF";
			// document.body.style.backgroundColor = this.before_backcolor;
			// console.log("恢复背景色");
		},
		beforeCreate: function() {
			console.log("configVo", this.configVo)
			console.log("init_wx", this.configVo.init_wx)
			if (this.configVo.init_wx) {
				var that=this
				// var url = "https://www.ebaoku.com/#/login"
				let url = "https://testzhijiandbnew.cugi.cn/#/login"
				// var url=window.location.href
				this.$axios.post("wx/h5Pay/wxH5Config?url=" + url).then(res => {
					console.log("微信配置Base", res)
					// this.init_wx=false
					this.configVo.sendinit_wx(false)
					if (res.code == 1) {
						wx.config({
							debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
							appId: res.data.appId, // 必填，公众号的唯一标识
							timestamp: res.data.timestamp, // 必填，生成签名的时间戳
							nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
							signature: res.data.signature, // 必填，签名
							jsApiList: ['scanQRCode', 'getLocation'] // 必填，需要使用的JS接口列表
						});
						console.log("微信配置成功")
						if (!localStorage.getItem("longAlat")) {
							console.log("无缓存定位")
							that.getLocation()
						} else if (!localStorage.getItem("siteInfo")) {
							console.log(("无缓存最近站点"))
							that.getLatelySite()
						}

					} else {
						Toast(res.msg)
					}
				})
			}


		},
		mounted: function() {
			document.title = this.title; //设置页面title
			// // if(!this.$route.params.no_token){//是否检测token,没有的话，跳转登陆页面
			// //    var access_token = localStorage.getItem("access_token");
			// //    if (!access_token) {
			// //        console.log("未登陆，跳转登陆页面");
			// //        this.$router.push("pwdlogin");
			// //    } 
			// // }


			document.body.style.backgroundColor = this.backcolor;
			// window.location.href="https://www.ebaoku.com?"
			// console.log("设置背景色", this.backcolor);
			// check_code();
		},
		// check_code(){
		// 	this.$route.query.code
		// }

	}
</script>