<template>
	<div class="certificateQuery">
		<!-- 搜索前 -->
		<div v-if="searchState==0">
			
			
			
			<!-- logo -->
			<div style="width: 53.3vw;height: 24.5vw;margin: auto;margin-top: 14px;">
				<img style="width: 53.3vw;height: 24.5vw;margin: auto;" src="../../assets/images/logo.png" />
			</div>

			<!-- 搜索 -->
			<div class="flex-row width90 input" style="margin-top: 22px;">
				<van-field class="searchInput" style="height: 34px;background: #F7F8FA;" v-model="certificateCode"
					:clearable="true" left-icon="search" :right-icon="require('@/assets/images/scanCode.png')"
					@click-right-icon="sacn" @clear="clearSearch" placeholder="请输入证书编号" />
				<div class="search" @click="search">查询</div>
			</div>

			<div style="width: 41.6vw;margin: auto;text-align: center;margin-top: 41px;" @click="sacn">
				<img style="width: 41.6vw;height: 40vw;" src="../../assets/images/sacnPhone.png" />
				<div class="text-18 margin-top-10">服务电话：4008771988</div>
			</div>

			<div class="width90" style="color: #A5A6AB;margin-top: 34px;">
				<div class="text-16">证书查询方式</div>
				<div class="text-14">
					1、扫描二维码查询：点击“扫一扫”图标，打开扫描界面，手机摄像头对准证书右下角的二维码进行扫描。
				</div>
				<div class="text-14">
					2、输入检验编号查询：在框内输入完整检验编号，点击“查询”按钮进行查询。
				</div>
			</div>
		</div>

		<!-- 证书详情 -->
		<div v-if="searchState==1">
			<!-- 搜索 -->
			<div class="flex-row width90 input"
				style="padding-top: 22px;position: fixed;top: 0;z-index: 2;right: 5vw;background: #FFFFFF;">
				<van-field class="searchInput" style="height: 34px;background: #F7F8FA;" v-model="certificateCode"
					:clearable="true" left-icon="search" :right-icon="require('@/assets/images/scanCode.png')"
					@click-right-icon="sacn" @clear="clearSearch" placeholder="请输入证书编号" />
				<div class="search" @click="search">搜索</div>
			</div>

			<div class="backgroundWhite" style="margin-top: 22px;height: 35px;"></div>
			<!-- 内容 -->
			<div v-if="loadingBig">
				<loadingBig></loadingBig>
			</div>
			<div class="width90 margin-top-10" v-else>
				<div class="text-14 blod">
					查询结果如下:
				</div>

				<van-image fit="cover" class="contenImg" :src="certificateInfo.certificateImgUrl"
					@click="imagePreview([certificateInfo.certificateImgUrl])"></van-image>

				<div>
					<div class="between borderBottom paddingtb10 text-14">
						<div class="gray">鉴定结果</div>
						<div>{{certificateInfo.checkResult}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14">
						<div class="gray">证书编号</div>
						<div>{{certificateInfo.certificateNumber}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14">
						<div class="gray">总质量</div>
						<div>{{certificateInfo.totalMass}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14">
						<div class="gray">颜色</div>
						<div>{{certificateInfo.color}}</div>
					</div>
					
					<div class="between borderBottom paddingtb10 text-14">
						<div class="gray">样式</div>
						<div>{{certificateInfo.style}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14">
						<div class="gray">形状</div>
						<div>{{certificateInfo.shape}}</div>
					</div>

					<!-- 玉石  certificateType    证书类型1 珠宝玉石证书  2 贵金属证书-->
					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">放大检查</div>
						<div>{{certificateInfo.enlargeCheck}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">紫外可见光谱</div>
						<div>{{certificateInfo.ultravioletVisibleSpectrum}}</div>
					</div>

					<!-- <div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">红外图谱（图片）</div>
						<div></div>
					</div>
					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">尺寸</div>
						<div>尺寸</div>
					</div>
					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">光线特征</div>
						<div>光线特征</div>
					</div>
					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">吸收光谱（图片）</div>
						<div class="solidBtn">查看图片</div>
					</div> -->

					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">红外检测描述</div>
						<div>{{certificateInfo.infraredDescription}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">折射率</div>
						<div>{{certificateInfo.refractive}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">密度</div>
						<div>{{certificateInfo.density}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==1">
						<div class="gray">是否测配石数</div>
						<div>{{certificateInfo.checkStoneNumber==1?"是":"否"}}</div>
					</div>
					<!-- 完 -->

					<!-- 金属 -->
					<div class="between borderBottom paddingtb10 text-14" v-if="certificateInfo.certificateType==2">
						<div class="gray">印记</div>
						<div>{{certificateInfo.imprint}}</div>
					</div>
					<!-- 完 -->


					<div class="between borderBottom paddingtb10 text-14">
						<div class="gray">其他</div>
						<div>{{certificateInfo.other}}</div>
					</div>

					<div class="between borderBottom paddingtb10 text-14">
						<div class="gray">备注</div>
						<div>{{certificateInfo.cnote}}</div>
					</div>

				</div>


				<div v-for="(img,key) in certificateInfo.sampleImgUrls" :key="key"
					>
					<van-image fit="cover" class="contenImg" :src="img.cutUrl" @click="imagePreview([img.cutUrl])"></van-image>
				</div>


			</div>
		</div>



		<tabbar></tabbar>
	</div>
</template>

<script>
	import {
		Toast
	} from 'vant';
	import BaseView from "@/views/BaseView.vue"
	import Tabbar from "@/components/tabbar.vue"
	import loadingBig from "@/components/loading-big.vue"
	import wx from 'weixin-js-sdk'
	import {
		ImagePreview
	} from 'vant';
	export default {
		extends: BaseView,
		components: {
			Tabbar,
			loadingBig
		},
		data() {
			return {
				title: "证书查询",
				backcolor: "#FFFFFF",
				loadingBig: true, //页面加载
				searchState: 0, //搜索页面展示，0默认或者搜索不到证书，1搜索到证书，展示证书详细
				state: 0, //0翡翠1黄金
				certificateCode: "",
				certificateInfo: ""
			}
		},
		created() {

			if (this.init_wx) {
				var url = "https://testzhijiandbnew.cugi.cn/#/login"
				// var url = "https://www.ebaoku.com/#/login"
				// var url=window.location.href
				this.$axios.post("wx/h5Pay/wxH5Config?url=" + url).then(res => {
					console.log("微信配置", res)
					this.init_wx = false
					if (res.code == 1) {
						wx.config({
							debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
							appId: res.data.appId, // 必填，公众号的唯一标识
							timestamp: res.data.timestamp, // 必填，生成签名的时间戳
							nonceStr: res.data.nonceStr, // 必填，生成签名的随机串
							signature: res.data.signature, // 必填，签名
							jsApiList: ['scanQRCode', 'getLocation'] // 必填，需要使用的JS接口列表
						});

					} else {
						Toast(res.msg)
					}
				})
			}

			var code = this.$route.query.code
			console.log("code", code)
			if (code) {
				this.certificateCode = code
				this.search()
			}
		},
		methods: {
			//扫码
			sacn() {
				console.log("扫码")
				var that = this
				wx.scanQRCode({
					needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
					scanType: ["qrCode", "barCode"], // 可以指定扫二维码还是一维码，默认二者都有
					success: function(res) {
						// 成功啦
						console.log("扫码成功", res)
						var url = res.resultStr
						var param=that.GetRequest(url)
						console.log("扫码成功param", param)
						that.certificateCode = param.code
						that.search()
					},
					error: function(err) {
						// 这里把err打出来可以看到结果
						// 根据打出的结果就知道了   有用给个赞哦
						console.log("扫码失败", err)

					}
				});
			},
			GetRequest(url) {
				if (url.indexOf('?') != -1) {
					let p = url.split('?')[1]
					let keyValue = p.split('&');
					console.log("keyValue",keyValue)
					let obj = {};
					for (let i = 0; i < keyValue.length; i++) {
						let item = keyValue[i].split('=');
						let key = item[0];
						let value = item[1];
						obj[key] = value;
					}
					console.log("obj",obj)
					return obj;
				} else {
					console.log('没有参数');
					alert('没有参数');
					return null;
				}
			},
			//搜索
			search() {
				this.loadingBig = true
				console.log("搜索", this.certificateCode)
				this.$axios.get("certificate/findByNumber/certificateByNumber", {
					params: {
						certificateNumber: this.certificateCode
					}
				}).then(res => {
					console.log("查询证书", res)
					setTimeout(() => {
						this.loadingBig = false
					}, 1000)
					// this.loadingBig=false
					if (res.data == "") {
						Toast.fail('查询无结果');
					} else {
						window.scrollTo(0, 0);
						var certificateInfo=res.data
						// var str=certificateInfo.shape.split(",")
						// certificateInfo.style=str[0]//样式
						// certificateInfo. fashion=str[1]//形状

						this.certificateInfo = certificateInfo
						this.searchState = 1
						this.certificateInfo.certificateImgUrl = res.data.certificateImgUrl
						var img = []
						if (res.data.sampleImgUrls) {
							img = res.data.sampleImgUrls.split(",")
						}

						// var imgArr=[]
						// img.map(value=>{
						// 	imgArr.push(this.imageUrl+value)
						// })

						this.certificateInfo.detailImgs = img
					}
				})
				// Toast.fail('查询无结果');
				// this.$router.push({
				// 	path:"/certificateDetail"
				// })
			},
			//清除搜索框
			clearSearch() {
				this.searchState = 0
			},
			//预览图片
			imagePreview(img) {
				console.log("预览图片", img)
				ImagePreview(img);
			}
		}

	}
</script>

<style>
	.searchInput {
		border-top-left-radius: 34px;
		border-bottom-left-radius: 34px;
	}

	.input .van-cell {
		line-height: 14px !important;
	}

	.search {
		background: #0080FD;
		color: #FFFFFF;
		width: 50px;
		height: 34px;
		border-top-right-radius: 34px;
		border-bottom-right-radius: 34px;
		text-align: center;
		line-height: 34px;
		font-size: 12px;
	}
	
	.van-image__img{
		border-radius: 0 !important;
	}
	
	.certificateQuery .van-icon{
		font-size:22px !important;
		line-height: 22px !important;
	}
</style>
